<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-form @submit.prevent="handleSubmit()">
      <v-card :disabled="loading">
        <v-card-title> Atualizar status dos processos </v-card-title>

        <v-card-text>
          <div class="mb-2">
            Adicione os números dos serviços separados por uma quebra de linha
          </div>
          <v-textarea
            v-model="form.leads"
            autofocus
            label="Serviços"
            placeholder="23EPST0030710.01
19EPST0018188.01
13EPST0002747.01
..."
            filled
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close()" text> Cancelar </v-btn>
          <v-btn color="primary" :loading="loading" type="submit">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { updateStatus } from "@/services/eps.js";

export default {
  data() {
    return {
      dialog: false,
      form: {
        leads: "",
      },
      loading: false,
    };
  },

  methods: {
    open() {
      this.dialog = true;
      this.form = {
        leads: "",
      };
    },
    close() {
      this.dialog = false;
    },
    async handleSubmit() {
      this.loading = true;
      try {
        await updateStatus(this.form);
        this.close();
        this.$store.commit(
          "success",
          "Recebemos sua solicitação, enviaremos um email quando estiver pronto"
        );
      } catch (error) {
        this.$store.commit(
          "error",
          "Não foi possível enviar solicitação, tente novamente mais tarde"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
