<template>
  <v-container>
    <v-card width="500" class="mx-auto">
      <v-card-title> Escolha uma opção </v-card-title>

      <v-list>
        <ScopeProvider scope="massive_actions.eps.update_status">
          <v-list-item @click="handleClickUpdateProcessStatus()">
            <v-list-item-content>
              <v-list-item-title>
                Atualizar status dos processos
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </ScopeProvider>

        <ScopeProvider scope="massive_actions.eps.set_leads_as_completed">
          <v-list-item @click="handleClickSetLeadsAsCompleted()">
            <v-list-item-content>
              <v-list-item-title>
                Marcar processos como concluídos
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </ScopeProvider>
      </v-list>
    </v-card>

    <UpdateProcessStatus ref="updateProcessStatus" />
    <SetLeadsAsCompleted ref="setLeadsAsCompleted" />
  </v-container>
</template>

<script>
import ScopeProvider from "@/components/ScopeProvider";
import UpdateProcessStatus from "../../../components/EPS/automations/UpdateProcessStatus.vue";
import SetLeadsAsCompleted from "../../../components/EPS/automations/SetLeadsAsCompleted.vue";

export default {
  components: {
    UpdateProcessStatus,
    SetLeadsAsCompleted,
    ScopeProvider,
  },

  methods: {
    handleClickUpdateProcessStatus() {
      this.$refs.updateProcessStatus.open();
    },

    handleClickSetLeadsAsCompleted() {
      this.$refs.setLeadsAsCompleted.open();
    },
  },
};
</script>
